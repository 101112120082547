<template>
  <div class="page-content"
        v-bind:class="{ 'extra-room': !hasBottomNavigation }">
    <v-speed-dial class="pulse"
                  :right="true"
                  :bottom="true">
      <template v-slot:activator>
        <v-btn class="speed-dial-button"
               fab fixed bottom right
               color="primary"
               @click="openAddDialog">
          <v-icon>add</v-icon>
        </v-btn>
      </template>
    </v-speed-dial>

    <!-- gateway overview -->
    <div class="mb-4">

      <v-skeleton-loader v-if="loading.siteInfo"
                         type="article"/>
      <no-gateway-card v-else-if="!gatewayAdded"/>
      <gateway-status-card @mac="setGatewayMac" v-else/>
    </div>

    <!-- devices -->
    <device-list v-if="!loading.siteInfo && gatewayAdded" />

    <!-- lock devices -->
    <lock-device-list></lock-device-list>

    <v-row>
      <!-- rules -->
      <v-col cols="12" sm="6" lg="6" v-if="gatewayAdded" v-show="showCard.hasAutomationV2">
        <rules-card/>
      </v-col>

      <!-- scenes -->
      <v-col cols="12" sm="6" lg="6" v-if="gatewayAdded" v-show="showCard.scenes">
        <scenes-card v-if="!loading.siteInfo && gatewayAdded" @hide-scenes-card="(value) => showCard.scenes = !value"/>
      </v-col>

      <!-- services -->
      <v-col cols="12" sm="6" lg="4" v-show="showCard.services">
        <services-card v-if="!loading.siteInfo && gatewayAdded"
                       @hide-services-card="(value) => showCard.services = !value"/>
      </v-col>

      <!-- schedules -->
      <v-col cols="12" sm="6" lg="4" v-show="showCard.schedules">
        <schedules-card v-if="!loading.siteInfo && gatewayAdded"
                        @hide-schedules-card="(value) => showCard.schedules = !value"/>
      </v-col>

      <!-- alerts -->
      <!-- Deactivated until further notice
      <v-col cols="12" sm="6" lg="4">
        <alerts-card v-if="!loading.siteInfo && gatewayAdded"/>
      </v-col>
      -->

      <!-- heating modes -->
      <v-col cols="12" sm="6" lg="4" v-show="showCard.heatingModes">
        <heating-modes-card v-if="!loading.siteInfo && gatewayAdded"
                            :gateway-mac="gatewayMac"
                            @hide-heatind-modes-card="(value) => showCard.heatingModes = !value"/>
      </v-col>

      <!-- heating scenes -->
      <v-col cols="12" sm="6" lg="4" v-show="showCard.heatingScenes">
        <heating-scenes-card v-if="!loading.siteInfo && gatewayAdded"
                             @hide-heating-scenes-card="(value) => showCard.heatingScenes = !value"/>
      </v-col>
    </v-row>

  </div>
</template>

<script>

import config from "@/config/config.app.json";
import GatewayStatusCard from "@/templates/components/living/GatewayStatusCard";
import DeviceList from "@/templates/components/living/DeviceList";
import NoGatewayCard from "@/templates/components/emptyStates/NoGatewayCard";
import requestHelper from "@/scripts/requestHelper";
import ScenesCard from "@/templates/components/living/ScenesCard";
import ServicesCard from "@/templates/components/living/ServicesCard";
import SchedulesCard from "@/templates/components/living/SchedulesCard";
import HeatingScenesCard from "@/templates/components/living/HeatingScenesCard.vue";
import HeatingModesCard from "@/templates/components/living/HeatingModesCard";
import RulesCard from "@/templates/components/living/RulesCard.vue";
import LockDeviceList from "@/templates/components/living/LockDeviceList.vue";

export default {
  name: "SmarthomePage",

  components: {
    RulesCard,
    HeatingScenesCard,
    SchedulesCard,
    ServicesCard,
    ScenesCard,
    NoGatewayCard,
    DeviceList,
    GatewayStatusCard,
    HeatingModesCard,
    LockDeviceList
  },

  data() {
    return {
      gatewayMac: null,
      timer: null,
      loading: {
        siteInfo: false
      },
      showCard: {
        scenes: true,
        services: true,
        schedules: true,
        heatingModes: true,
        heatingScenes: true,
        hasAutomationV2: false
      },
      siteType: null,
    }
  },

  computed: {
    /**
     * returns true if a gateway was added to the currently active site
     *
     * @returns {boolean}
     */
    gatewayAdded() {
      return this.siteType !== 'no_gateway'
    },

    hasBottomNavigation() {
      return this.$route.meta.bottomNavigation === 'true'
    },

    hasDiscoverPage() {
      return config.hasDiscoverPage === 'true'
    }
  },

  methods: {
    /**
     * opens overlay to create new automation or add devices
     */
    openAddDialog() {
      this.$root.bisadialog.toggle('addSmarthomeComponent', true, {gatewayAdded: this.gatewayAdded, gatewayMac: this.gatewayMac})
    },
    /**
     * gets data for the currently active site
     *
     * @param showLoader
     */
    getSiteInfo(showLoader) {
      if (showLoader) {
        this.loading.siteInfo = true
      }
      this.$rhRequest.sendGet({
        endpoint: "site/get-general-information",
      }, (resp) => {
        this.siteType = resp?.data?.data?.siteType
        this.loading.siteInfo = false
      }, (err) => {
        console.error(err)
        if (showLoader) { // do not spam
          this.$root.bisatoast.error({message: this.$t('app.generic-error')})
        }
        this.loading.siteInfo = false
      })
    },

    /**
     * continuously updates the data
     */
    updateData() {
      this.timer = setInterval(() => {
        this.getSiteInfo(false)
      }, config.updateInterval)
    },
    setGatewayMac(value) {
      this.gatewayMac = value
    },

    openDevice(deviceId) {
      this.$rhRequest.sendGet({
        endpoint: "devices/get?ids=" + deviceId,
      }, (resp) => {
        this.timeout = false
        const deviceList = Object.values(resp?.data?.data)
        let devices = deviceList.filter(device => device.encryptedId === deviceId)
        const device = devices.shift()
        this.$root.bisadialog.toggle('device', true, {device: device})
        this.$root.bisadialog.callDialogInit('device')
      }, (err) => {
        console.error(err)
        if (err.code === 'ECONNABORTED') {
          this.timeout = true
        }
      })
    },
    loadCachedData() {
      Promise.all([
        this.$caching.getData('gatewayMac').then((gatewayMac) => {
          if (gatewayMac !== -1) {
            this.gatewayMac = gatewayMac
          }
        }),
        this.$caching.getData('siteType').then((siteType) => {
          if (siteType!== -1) {
            this.siteType = siteType
          }
        })
      ]).then(() => {
        this.loading.siteInfo = false
      })
    }
  },

  mounted() {
    this.loading.siteInfo = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
        () => this.getSiteInfo(false),
        () => this.updateData()
    )

    const deviceId = this.$route.params?.deviceId
    if (deviceId) {
        this.openDevice(deviceId)
    }
  },

  created() {
    this.showCard.hasAutomationV2 = (/true/).test(config.hasAutomationV2);
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    gatewayMac(newVal) {
      this.$caching.saveData('gatewayMac', newVal)
    },
    siteType(newVal) {
      this.$caching.saveData('siteType', newVal)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/smarthome-page.scss';
</style>
