<!-- This component is specifically designed for the Thermostat Mode property (ir-thermostat-mode) of RMT_ZXT800 -->
<template>
  <v-card flat tile class="ma-0 pa-0">
    <v-overlay v-if="updatingMode" color="navBackground" absolute z-index="4" opacity="0.7">
      <v-progress-circular indeterminate
                           color="primary"/>
    </v-overlay>
    <v-card-text v-if="actuator.value === ControllerModes.RESUME || isResuming" class="pa-0">
      <v-progress-circular indeterminate color="primary" class="mr-2"/>
      {{ $t('ir-controller-mode-switch.status-resuming') }}
    </v-card-text>
    <v-card-text v-else class="mode-buttons px-4 py-4 d-flex justify-space-between">
      <v-btn icon
             :disabled="updatingMode"
             :class="!irControllerActive ? 'error--text' : 'primary--text'"
             @click="irControllerActive ? setControllerMode(ControllerModes.OFF) : setControllerMode(ControllerModes.RESUME)">
        <span class="font-size-10 material-symbols-outlined pl-2">power_settings_new</span>
        <span class="font-size-02 pt-1">ACTIVE</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive || updatingMode"
             :class="(irControllerActive && actuator.value === ControllerModes.HEAT ? 'error--text' : '')"
             @click="setControllerMode(ControllerModes.HEAT)">
        <span class="font-size-10 material-symbols-outlined pl-2">mode_heat</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.heat') }}</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive || updatingMode"
             :class="(irControllerActive && actuator.value === ControllerModes.COOL ? 'info--text' : '')"
             @click="setControllerMode(ControllerModes.COOL)">
        <span class="font-size-10 material-symbols-outlined pl-2">mode_cool</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.cool') }}</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive || updatingMode"
             :class="(irControllerActive && actuator.value === ControllerModes.DRY_AIR ? 'warning--text' : '')"
             @click="setControllerMode(ControllerModes.DRY_AIR)">
        <span class="font-size-10 material-symbols-outlined pl-2">cool_to_dry</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.dry-air') }}</span>
      </v-btn>
      <v-btn icon
             :disabled="!irControllerActive || updatingMode"
             :class="(irControllerActive && actuator.value === ControllerModes.FAN_ONLY ? 'success--text' : '')"
             @click="setControllerMode(ControllerModes.FAN_ONLY)">
        <span class="font-size-10 material-symbols-outlined pl-2">mode_fan</span>
        <span class="font-size-02 pt-1">{{ $t('ir-controller-card.mode-label.fan-only') }}</span>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>


export default {
  name: "IRControllerModeSwitch",

  props: {
    device: Object,
    actuator: Object,
    dense: Boolean
  },

  data: function () {
    return {
      irControllerActive: false,
      ControllerModes: {
        OFF: 0,
        HEAT: 1,
        COOL: 2,
        AUTO: 3,
        RESUME: 5,
        FAN_ONLY: 6,
        DRY_AIR: 8
      },
      updatingMode: false,
      isResuming: false,
      timeout: null
    }
  },

  methods: {
    setControllerMode(mode) {
      if (this.actuator.value === mode) {
        return
      }
      this.updatingMode = true
      this.$rhRequest.sendPost({
            endpoint: 'devices/update-property',
            data: {
              'deviceId': this.device.id.toString(),
              'property': 'ir-thermostat-mode',
              'value': mode.toString()
            }
          },
          () => {
            if (mode === this.ControllerModes.RESUME) {
              // reset isResuming after one minute if nothing has happened
              this.timeout = setTimeout(() => {
                    this.isResuming = false
                  }, 60000
              )
            }
          },
          () => {
            this.updatingMode = false
            this.$root.bisatoast.error({
              message: this.$t("ir-controller.set-controller-mode.error")
            })
          }
      )
    }
  },

  mounted() {
    this.irControllerActive = this.actuator.value !== this.ControllerModes.OFF
  },

  watch: {
    actuator(newVal, oldVal) {
      this.irControllerActive = newVal.value !== this.ControllerModes.OFF
      // wait until the actual resumed value is present
      this.isResuming = this.isResuming && newVal.value === this.ControllerModes.OFF
          || (oldVal.value === this.ControllerModes.RESUME && newVal.value === this.ControllerModes.OFF)
      if (this.isResuming === false) {
        clearTimeout(this.timeout)
      }
      // reset loader when mode has changed
      if (newVal.value !== oldVal.value) {
        this.updatingMode = false
      }
    }
  }
}
</script>
