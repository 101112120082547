<template>
  <fullscreen-overlay-frame :title="title"
                            icon="control_point_duplicate"
                            :color="color"
                            centered
                            closable
                            @close="abort">

    <template v-slot:content>
      <v-form v-model="lockValid">

        <v-list>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{$t('add-wilka-lock-dialog.field.name')}}</v-list-item-title>
              <v-text-field
                  v-model="lockData.name"
                  solo
                  :rules="nameRules"
                  required>
              </v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{$t('add-wilka-lock-dialog.field.bluetoothAddress')}}</v-list-item-title>
              <v-text-field
                  ref="bluetoothAddress"
                  v-model="lockData.bluetoothAddress"
                  @blur="checkBluetoothIdUniqueness"
                  solo
                  :rules="bluetoothAddressRules"
                  required>
              </v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-0">
            <v-list-item-content>
              <v-list-item-title>{{$t('add-wilka-lock-dialog.field.communicationKey')}}</v-list-item-title>
              <v-text-field
                  v-model="lockData.communicationKey"
                  solo
                  :rules="communicationKeyRules"
                  required>
              </v-text-field>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-form>
    </template>

    <template v-slot:actions>
      <v-btn class="stepper-button" :disabled="!lockValid" color="primary" @click="saveLock()">{{ $t("add-wilka-lock-dialog.buttonLabel.save") }}</v-btn>
    </template>
  </fullscreen-overlay-frame>
</template>

<script>
import FullscreenOverlayFrame from "@/templates/dialogs/FullscreenOverlayFrame";
import CryptoJS from 'crypto-js'

export default {
  name: 'AddWilkaLock',
  props: ['data'],
  components: {FullscreenOverlayFrame},
  data: function () {
    return {
      title: this.$t('add-wilka-lock-dialog.title'),
      color: 'primary',
      edit: false,
      lockData: {
        id: null,
        name: '',
        bluetoothAddress: '',
        communicationKey: ''
      },
      nameRules: [
        v => !!v || this.$t("add-wilka-lock-dialog.validation.notEmpty"),
        v => (v && v.length <= 30) || this.$t("add-wilka-lock-dialog.validation.name.length", {maxLength: '30'})
      ],
      bluetoothAddressRules: [
        v => !!v || this.$t("add-wilka-lock-dialog.validation.notEmpty"),
        v => (v && ((/^([0-9A-F]{2}:){5}([0-9A-F]{2})$/.test(v)) || (/^([0-9A-F]{12})$/.test(v)))) || this.$t('add-wilka-lock-dialog.validation.bluetoothId.length'),
      ],
      communicationKeyRules: [
        v => !!v || this.$t("add-wilka-lock-dialog.validation.notEmpty"),
        v => v.toString().length >= 6 || this.$t('add-wilka-lock-dialog.validation.communicationKey.length'),
        v => v.toString().length <= 16 || this.$t('add-wilka-lock-dialog.validation.communicationKey.length')
      ],
      lockValid: false
    }
  },
  methods: {
    init() {
      // empty function to prevent error in console
    },
    saveLock() {
      let communicationKeyMD5 =  CryptoJS.MD5(this.lockData.communicationKey).toString()
      let bluetoothId = this.formatBluetoothId(this.lockData.bluetoothAddress)

      let endpoint = 'wilka';

      let requestData = {
        'data': {
          'type': 'lockCylinders',
              'attributes': {
                'bluetoothId': bluetoothId,
                'communicationKey': communicationKeyMD5,
                'name': this.lockData.name,
                'description': '',
                'siteIds': [localStorage.activeSite],
                'clusterId': ''
          }
        }
      }

      this.$rhRequest.sendPost({
            endpoint: endpoint,
            data: requestData
          },
          () => { this.close() },
          () => {
            this.$root.bisatoast.error({
              message: 'add-wilka-lock-dialog.error.adding-failed',
              showCloseBtn: true
            })
          }
      )
    },

    formatBluetoothId(bluetoothId) {
      if(!bluetoothId.includes(':')) {
        return bluetoothId.replace(/(..)/g, '$1:').slice(0,-1).toUpperCase()
      }
      else {
        return bluetoothId.toUpperCase()
      }
    },
    checkBluetoothIdUniqueness() {
      let btid = this.formatBluetoothId(this.lockData.bluetoothAddress)
      this.$rhRequest.sendGet({
        endpoint: 'wilka?filter[bluetoothId]=' + btid,
      },
          (response) => {
            let result = response.data.data.length < 1
            if (!result) {
              console.log('error')
              this.$refs.bluetoothAddress.errorMessages = [ this.$t('add-wilka-lock-dialog.exists.error') ]
              this.formValid = false
            } else {
              this.$refs.bluetoothAddress.errorMessages = ''
            }
          },
          (e) => { console.error(e) }
      )
    },


    /**
     * abort adding process
     */
    abort() {
      this.close()
    },

    /**
     * close dialog
     */
    close() {
      this.$root.bisadialog.toggle('addWilkaLock')
    }
  },
};
</script>

