<!-- This card contains all available IR Controllers for display on the /home page (Alfanar use case) -->
<template>
  <v-skeleton-loader v-if="loading"
                     type="card"/>

  <content-card v-else
                :title="$t('ir-controller-card.title')"
                :is-material-symbol="true"
                icon="airwave">
    <template v-slot:content>
      <generic-device-card v-for="irController in irControllers" v-bind:key="irController.id"
                           :device="irController"
                           class="list-item straight"
                           dense flat/>
    </template>
  </content-card>
</template>

<script>
import ContentCard from "@/templates/components/ContentCard";
import requestHelper from "@/scripts/requestHelper";
import config from "@/config/config.app.json";
import GenericDeviceCard from "@/templates/components/devices/GenericDeviceCard.vue";

export default {
  name: "IRControllerCard",
  components: {GenericDeviceCard, ContentCard},

  data: function () {
    return {
      loading: true,
      irControllers: [],
      timer: null
    }
  },

  methods: {
    updateData() {
      this.timer = setInterval(() => {
        this.getData()
      }, config.updateInterval)
    },

    getData(showLoader, stopControllerLoader) {
      if (showLoader) {
        this.loading = true
      }

      this.$rhRequest.sendGet({
        endpoint: 'devices/get',
        params: {
          deviceType: 'infrared-controller'
        }
      }, (response) => {

        if (stopControllerLoader) {
          this.loadingSlider = []
          this.loadingController = []
        }

        this.irControllers = Object.values(response?.data?.data)
        if (!this.irControllers || this.irControllers.length < 1) {
          this.$emit('hide-ircontroller', true)
        }

        this.loading = false
      }, (error) => {
        console.error(error)
        this.$emit('hide-ircontroller', true)
        this.loading = false
      })
    },

    loadCachedData() {
      this.$caching.getData('irControllers').then((data) => {
        if(data !== -1) {
          this.irControllers = data
          this.loading = false
          if (!this.irControllers || this.irControllers.length < 1) {
            this.$emit('hide-ircontroller', true)
          } else {
            this.$emit('hide-ircontroller', false)
          }
        }
      })
    }
  },

  mounted() {
    this.loading = true
    this.loadCachedData()
    requestHelper.startDelayedRequest(
      () => this.getData(false),
      () => this.updateData()
    )
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },
  watch: {
    irControllers(newVal) {
      this.$caching.saveData('irControllers', newVal)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/living/ir-controller-card';
</style>
